(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('canceropoleGsoApp')
        .constant('VERSION', "1.2.15")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('BUILD_DATE', "2023-9-10 14:52:56")
;
})();
